<template>
  <div class="bdc_main_wrap whero">
    <div class="thw_content">
      <div class="internal-sub-nav">
        <div
          v-for="(tab, i) in tabOptions"
          :key="i"
          class="isn-item"
          :class="{ active: tab.id === selectedTab, disabled: tab.disabled }"
          @click="tabOptions.length === 1 ? null : onTabClick(tab)"
        >
          {{ tab.value }}
        </div>
      </div>
    </div>
    <div v-if="tableauJwtToken" class="bdc_top_panel_wrap">
      <tableau-viz
        id="tableauViz"
        ref="tableauContainer"
        :src="tableauViewURL"
        :token="tableauJwtToken"
        :on-viz-load-error="handleTableauError"
        toolbar="top"
        hide-tabs
      >
        <custom-parameter name=":showShareOptions" value="false"></custom-parameter>
        <viz-filter field="Advertiser" :value="advertiserId"> </viz-filter>
        <viz-filter field="Start Date" :value="startDate"></viz-filter>
        <viz-filter field="End Date" :value="endDate"></viz-filter>
        <viz-filter field="Days To Convert Parameter" :value="updateConversionWindow"></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics' || mniTemplateId !== 17"
          field="Event Category"
          :value="eventFilters.eventCategory"
        ></viz-filter>
        <viz-filter
          v-if="
            (selectedTab !== 'journeyanalytics' || mniTemplateId !== 17) &&
              eventFilters.eventSubcategory != null
          "
          field="Event Subcategory"
          :value="eventFilters.eventSubcategory"
        ></viz-filter>

        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Media Type"
          :value="selectedMediaTypeOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Campaign ID"
          :value="campaignOptionsValues"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="AdReady ID"
          :value="selectedAudienceOptions"
        ></viz-filter>
        <viz-filter
          v-if="selectedTab !== 'journeyanalytics'"
          field="Creative ID"
          :value="creativeOptionsOptions"
        ></viz-filter>
        <viz-filter field=":refresh" value="yes"></viz-filter>
        <viz-parameter field="embed" value="yes"></viz-parameter>
      </tableau-viz>
      <!-- <tableau-viz id="tableauViz"></tableau-viz> -->
    </div>
    <div v-else>
      Something went wrong. Please contact Digital Remedy technical support.
    </div>
  </div>
</template>
<script>
import _ from 'underscore';
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import axios from 'axios';
import { TableauViz, TableauEventType } from '@tableau/embedding-api';
import {
  MNI_REPORTING_TABLEAU_URL,
  DEFAULT_CONVERSION_WINDOW,
  MNI_OVERVIEW_TAB_OPTIONS,
  DEFAULT_MNI_ADVERTISER_TEMPLATE_MAPPING,
} from '~/constant';
import { formatDateForAPI } from '~/util/apiDateFormat';
import config from '~/config';
import { isDemoInstance } from '~/util/utility-functions';

export default {
  // components: {
  //   TableauViz: new TableauViz(),
  // },
  data() {
    const tabOptions = MNI_OVERVIEW_TAB_OPTIONS;
    let selectedTab = '';
    let selectedTabTitle = '';
    let selectedTabUrl = '';
    let selectedTabSubUrl = '';
    let selectedTabDataUrl = '';
    if (tabOptions?.length) {
      const defaultSelectedTab = tabOptions.find((t) => t.selected) || this.tabOptions[0];
      selectedTab = defaultSelectedTab?.id || tabOptions[0].id;
      this.$store.set('dashboard/selectedDashboardTab', selectedTab);

      selectedTabTitle = defaultSelectedTab?.value || tabOptions[0].value;
      if (isDemoInstance()) {
        selectedTabUrl = defaultSelectedTab?.demoViewUrl || tabOptions[0].demoViewUrl;
        selectedTabSubUrl = defaultSelectedTab?.demoViewSubUrl || tabOptions[0].demoViewSubUrl;
      } else {
        selectedTabUrl = defaultSelectedTab?.viewUrl || tabOptions[0].viewUrl;
        selectedTabSubUrl = defaultSelectedTab?.viewSubUrl || tabOptions[0].viewSubUrl;
        selectedTabDataUrl = defaultSelectedTab?.dataUrl || tabOptions[0].dataUrl;
      }
    }
    return {
      tabOptions,
      selectedTab,
      selectedTabTitle,
      selectedTabUrl,
      selectedTabSubUrl,
      selectedTabDataUrl,
      MNI_REPORTING_TABLEAU_URL,
      tableauJwtToken: null,
      tableauError: false,
      sheets: [],
      selectedSheet: '',
      viz: null,
      container: 'tableau-viz',
      tableauDataUrl: null,
      tableauRestToken: null,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    dates: get('dashboard/dates'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    filtersApplied: get('dashboard/filtersApplied'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    mniTemplateId: get('common/mniTemplateId'),

    advertiserId() {
      return this.advertiser ? this.advertiser.xandrAdvertiserId : 0;
    },
    startDate() {
      return formatDateForAPI(this.dates.startDate);
    },
    endDate() {
      return formatDateForAPI(this.dates.endDate);
    },
    tableauViewURL() {
      let selectedTemplateId = this.advertiser?.templateId;
      selectedTemplateId =
        selectedTemplateId !== undefined || selectedTemplateId !== null
          ? selectedTemplateId
          : DEFAULT_MNI_ADVERTISER_TEMPLATE_MAPPING;
      const templateURL = `${config.TABLEAU_URL}${this.selectedTabUrl}`;
      let tableauViewURL = '';
      if (this.selectedTab === 'journeyanalytics') {
        tableauViewURL = `${templateURL}`;
      } else {
        const urlTemplId = isDemoInstance() ? '' : `-${selectedTemplateId}`;
        tableauViewURL = `${templateURL}${urlTemplId}${this.selectedTabSubUrl}${urlTemplId}`;
      }
      this.$store.dispatch('common/setMniTemplateId', selectedTemplateId);
      return tableauViewURL;
    },
    // below code return empty string when all items are selected sending empty string considered as all selected in tablue.
    selectedMediaTypeOptions() {
      const allChecked = this.mediaTypeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      return this.mediaTypeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.value)
        .join(',');
    },
    campaignOptionsValues() {
      const allChecked = this.campaignOptions.every((f) => f?.checked === true);
      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }
      const result = this.campaignOptions.filter((f) => f?.checked);
      const campaignIds =
        result
          .map((res) => res.key)
          .filter((fres) => fres)
          .join(',') || '';
      return campaignIds;
    },
    selectedAudienceOptions() {
      const allChecked = this.audienceOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedAudience = this.audienceOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedAudience;
    },
    updateConversionWindow() {
      return this.conversionWindow ? this.conversionWindow : DEFAULT_CONVERSION_WINDOW;
    },
    creativeOptionsOptions() {
      const allChecked = this.creativeOptions.every((item) => item.checked === true);

      if (allChecked) {
        return ''; // If all items are checked, return an empty string
      }

      const selectedCreative = this.creativeOptions
        .filter((item) => item.checked === true)
        .map((item) => item.key)
        .join(',');

      return selectedCreative;
    },
    eventFilters() {
      const selectedEvent = this.eventOptions.filter((event) => event.selected === true);
      let eventCategory = [];
      let eventSubcategory = [];

      if (selectedEvent.length > 0) {
        // Collect parent categories
        const category = selectedEvent.map((event) => event.category);
        eventCategory = ['~~null~', ...category];

        // Filter out events that have subcategories and are selected
        const eventsWithChilds = selectedEvent.filter(
          (event) => event.numSubCategories > 0 && event.children
        );

        // Collect subcategories
        const subcategory = eventsWithChilds.flatMap(
          (event) =>
            event.children
              .filter((subEve) => subEve.selected) // Filter based on the selected property
              .map((subEve) => subEve.category) // Map to return only the category
        );

        if (isBlank(subcategory)) {
          eventSubcategory = null;
        } else {
          eventSubcategory = ['~~null~', ...subcategory];
        }

        // Check if all parent and child items are selected
        const allParentsSelected = this.eventOptions.every((event) => event.selected === true);
        const allChildrenSelected = this.eventOptions.every((event) =>
          event.children ? event.children.every((child) => child.selected === true) : true
        );

        // If all items are selected, both arrays should be empty
        if (allParentsSelected && allChildrenSelected) {
          eventCategory = [];
          eventSubcategory = [];
        }
      }
      return {
        eventCategory,
        eventSubcategory,
      };
    },
  },
  watch: {
    advertiserId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    accountId: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    dates: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    mediaTypeOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    conversionWindow: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          this.debounceTableauAuthToken();
        }
      },
    },
    campaignOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    audienceOptions: {
      immediate: false,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          if (o) this.debounceTableauAuthToken();
        }
      },
    },
    filtersApplied: {
      deep: true,
      async handler(n) {
        if (n) {
          this.$store.set('dashboard/filtersApplied', false);
          this.$store.set('dashboard/filtersAppliedLoading', false);
          this.debounceTableauAuthToken();
        }
      },
    },
    tableauJwtToken: {
      deep: true,
      async handler(n, o) {
        if (n) {
          const tableauComponent = document.getElementById('tableauViz');
          // const tableauViz = this.viz;
          if (!o && !this.viz) {
            // console.log('watch tableauJwtToken initializeTableau');
            this.initializeTableau(tableauComponent);
            // await this.fetchTableauRestApiAuthToken();
          }
          // if (!this.viz) {
          //   this.initializeTableau(tableauViz);
          // }
          // if (!o) this.initTableau(this.tableauViewURL);
          if (this.viz) {
            this.viz.addEventListener(TableauEventType.VizLoadError, this.handleTableauError);
            this.fetchTableauData();
            // console.log('watch tableauJwtToken: ', n);
            // console.log('watch referrer: ', document.referrer);
            // console.log('watch tableauViewURL: ', this.tableauViewURL);
            // console.log('watch tableauViz: ', tableauViz);
          }
        }
      },
    },
    selectedTabTitle: {
      deep: true,
      async handler(n, o) {
        if (!_.isEqual(n, o)) {
          // console.log('watch selectedTabTitle: ', n);
          this.fetchTableauData();
        }
      },
    },
  },
  async mounted() {
    await this.getTableauAuthToken();
    // await this.fetchTableauRestSignin();
    await this.fetchTableauRestApiAuthToken();
    this.selectedTabTitle = this.selectedTabTitle ? this.selectedTabTitle : 'Overview';
    this.fetchTableauData();
    // console.log('mounted selectedTabTitle: ', this.selectedTabTitle);

    // tableau.extensions.initializeAsync().then(() => {
    //   let dashboard = {};
    //   dashboard = tableau?.extensions?.dashboardContent?.dashboard;
    //   console.log('dashboard: ', dashboard);
    //   this.sheets = dashboard.worksheets;
    //   console.log('sheets: ', this.sheets);
    //   if (this.sheets.length > 0) {
    //     this.selectedSheet = this.sheets[0].name;
    //   }
    // });

    // this.initTableau(this.tableauViewURL);
  },
  methods: {
    debounceTableauAuthToken() {
      _.debounce(this.getTableauAuthToken(), 100, true);
    },
    async getTableauAuthToken(maxRetries = 3) {
      console.log('retries left: ', maxRetries);
      if (maxRetries < 1) return;
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau`, {
          withCredentials: true,
        })
        .then((d) => {
          this.tableauJwtToken = d.data.result;
          if (d.status !== 200) {
            this.getTableauAuthToken(maxRetries - 1);
          }
        });
    },
    checkIfDemoInstance() {
      return isDemoInstance();
    },
    onTabClick(tab) {
      // console.log('onTabClick tab: ', tab);
      if (tab.disabled) {
        return;
      }
      this.selectedTab = tab.id;
      this.selectedTabTitle = tab.value;
      this.tableauDataUrl = tab.dataUrl;
      if (isDemoInstance()) {
        this.selectedTabUrl = tab.demoViewUrl;
        this.selectedTabSubUrl = tab.demoViewSubUrl;
      } else {
        this.selectedTabUrl = tab.viewUrl;
        this.selectedTabSubUrl = tab.viewSubUrl;
        this.selectedTabDataUrl = tab.dataUrl;
      }
      this.$store.set('dashboard/selectedDashboardTab', tab.id);

      this.debounceTableauAuthToken();
    },
    async initializeTableau(tableauComponent) {
      const viz = new TableauViz();
      // console.log('viz: ', viz);

      viz.src = this.tableauViewURL;
      viz.token = this.tableauJwtToken;
      viz.toolbar = 'hidden';

      if (tableauComponent) {
        tableauComponent.appendChild(viz);
      }

      this.viz = viz;
      // let workbook = null;
      // workbook = viz.workbook;
      // console.log('workbook: ', workbook);

      // const vizActiveSheet = viz.workbook.activeSheet;
      // console.log('vizActiveSheet: ', vizActiveSheet);
      // if (vizActiveSheet.sheetType === 'worksheet') {
      //   const dataTableReader = await vizActiveSheet.getSummaryDataReaderAsync();
      //   const dataTable = await dataTableReader.getAllPagesAsync();
      //   console.log('dataTable: ', dataTable);
      //   await dataTableReader.releaseAsync();
      //   // ... process data table ...
      // }

      // if (tableauComponent) {
      //   tableauComponent.appendChild(viz);
      // }
    },
    handleTableauError(e) {
      console.log('Error fetching Tableau: ', e);
      if (e.detail.errorCode === 'auth-failed') {
        this.debounceTableauAuthToken();
      }
    },
    async fetchTableauRestSignin() {
      // console.log('fetchTableauRestSignin');
      const url = 'https://dev-tableau.digitalremedy.com/api/3.19/auth/signin';
      let body = `{
        "credentials": {
          "jwt": "${this.tableauJwtToken}",
          "site": {
            "contentUrl": ""
          }
        }
      }`;
      body = body.JSON.parse();
      let data = {};
      // console.log('fetchTableauRestSignin body: ', body);
      try {
        axios
          .post(url, {
            headers: {
              // 'X-Tableau-Auth':
              //   'OkDmiVQCTaaJCLkO0OmtXQ|VPO2nIuwOxGraVV0GHKyKQ8KKcRqLyuk|e5a6f961-1bb5-4f47-a077-050c85df981f',
              // 'Content-Type': 'application/json',
              // Accept: 'application/json',
              // 'Access-Control-Allow-Origin': true,
              // withCredentials: true,
            },
            body,
          })
          .then((d) => {
            // this.tableauJwtToken = d.data.result;
            data = d.data.result;
          });
        // console.log('fetchTableauRestSignin data: ', data);
        return data;
      } catch (err) {
        console.error('error in fetchTableauRestSignin ->', err, this.advertiser.id);
        return '';
      }
    },
    async fetchTableauRestApiAuthToken() {
      axios
        .get(`${config.ADREADY_URL}/api/token/tableau/sign/${this.advertiser.id}`, {
          withCredentials: true,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': true,
        })
        .then((d) => {
          // console.log('fetchTableauRestApiAuthToken d: ', d);
          if (d.data.result) {
            this.tableauRestToken = d.data.result;
          }
          // console.log('token: ', d.data.result);
          return d;
        });
    },

    async fetchTableauData() {
      // const params =
      //   '?vf_Advertiser=5779370&vf_Start+Date=2025-01-04&vf_End+Date=2025-02-02&vf_Days+To+Convert+Parameter=31&vf_Event+Category=~~null~%2Clead&vf_Event+Subcategory=~~null~%2CAAI-ThankYouPage%2CBTI-ThankYouPage%2CSTVT-ThankYouPage%2CMillerMotte-ThankYouPage%2CMcCann-ThankYouPage&vf_Media+Type=&vf_Campaign+ID=13501&vf_AdReady+ID=&vf_Creative+ID=';
      // const url = this.tableauDataUrl + params;
      const params = this.createTableauDataParams();
      const url = this.selectedTabDataUrl + params;
      // let tableauData = {};
      // console.log('url: ', url);
      try {
        // axios
        //   .get(url, {
        //     headers: {
        //       // 'X-Tableau-Auth':
        //       //   'OkDmiVQCTaaJCLkO0OmtXQ|VPO2nIuwOxGraVV0GHKyKQ8KKcRqLyuk|e5a6f961-1bb5-4f47-a077-050c85df981f',
        //       'Content-Type': 'application/json',
        //       // Accept: 'application/json',
        //       'Access-Control-Allow-Origin': true,
        //       withCredentials: true,
        //     },
        //   })
        //   .then((d) => {
        //     this.tableauJwtToken = d.data.result;
        //   });
        // console.log('fetchTableauData tableauRestToken: ', this.tableauRestToken);
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-Tableau-Auth': this.tableauRestToken,
            // 'Content-Type': 'application/json',
            // 'X-Tableau-Auth':
            //   'GzAuwCV7Tyyx29cM_7UQNg|9g64AuQ9COTpg6fq5bpeakwjaHvmFAp5|e5a6f961-1bb5-4f47-a077-050c85df981f',
          },
        });
        // .then((d) => {
        //   console.log('d: ', d.toString());
        //   tableauData = d.data.result;
        //   console.log('tableauData: ', tableauData);
        // });
        // console.log('response: ', response);
        // const responseText = await response.text();
        // console.log('responseText: ', responseText);
        return response;
      } catch (err) {
        console.error('error in fetchTableauData ->', err, this.advertiser.id);
        return {};
      }

      // const data = await appConsoleDataLayer.fetchCTVSummary(
      //   this.advertiserId,
      //   this.solutionId,
      //   this.payload
      // );
      // console.log('data: ', data);
    },
    createTableauDataParams() {
      let params = '';
      const advertiserParam = `?vf_Advertiser=${this.advertiserId ? this.advertiserId : ''}`;
      const startDateParam = `&vf_Start+Date=${this.startDate ? this.startDate : ''}`;
      const endDateParam = `&vf_End+Date=${this.endDate ? this.endDate : ''}`;
      const daysToConverParam = `&vf_Days+To+Convert+Parameter=${
        this.updateConversionWindow ? this.updateConversionWindow : ''
      }`;
      const eventCategoryParam = `&vf_Event+Category=${
        this.eventFilters.eventCategory ? this.eventFilters.eventCategory : ''
      }`;
      const eventSubCategoryParam = `&vf_Event+Subcategory=${
        this.eventFilters.eventSubcategory ? this.eventFilters.eventSubcategory : ''
      }`;
      const mediaTypeParam = `&vf_Media+Type=${
        this.selectedMediaTypeOptions ? this.selectedMediaTypeOptions : ''
      }`;
      const campaignIdParam = `&vf_Campaign+ID=${
        this.campaignOptionsValues ? this.campaignOptionsValues : ''
      }`;
      const adreadyIdParam = `&vf_AdReady+ID=${
        this.selectedAudienceOptions ? this.selectedAudienceOptions : ''
      }`;
      const creativeIdParam = `&vf_Creative+ID=${
        this.creativeOptionsOptions ? this.creativeOptionsOptions : ''
      }`;
      params =
        advertiserParam +
        startDateParam +
        endDateParam +
        daysToConverParam +
        eventCategoryParam +
        eventSubCategoryParam +
        mediaTypeParam +
        campaignIdParam +
        adreadyIdParam +
        creativeIdParam;

      // console.log('params: ', params);
      return params;
    },
    // async fetchTableauData() {
    //   console.log('fetchTableauData');
    //   // // const viz = new TableauViz();
    //   // const viz = document.querySelector('#tableauViz');
    //   console.log('viz: ', this.viz);
    //   // console.log('viz children: ', viz.children);
    //   // const workbook = this.viz?.getWorkbook();
    //   // console.log('workbook: ', workbook);
    //   const vizWorkbook = this.viz?.workbook;
    //   console.log('vizWorkbook: ', vizWorkbook);
    //   const vizActiveSheet = this.viz?.workbook?.activeSheet;
    //   console.log('vizActiveSheet: ', vizActiveSheet);
    //   if (vizActiveSheet && vizActiveSheet.sheetType === 'worksheet') {
    //     const dataTableReader = await vizActiveSheet.getSummaryDataReaderAsync();
    //     const dataTable = await dataTableReader.getAllPagesAsync();
    //     console.log('dataTable: ', dataTable);
    //     await dataTableReader.releaseAsync();
    //     // ... process data table ...
    //   }

    //   // const workbook = this.viz.getWorkbook();
    //   // console.log('workbook: ', workbook);
    // },
    // initTableau(tableauViewURL) {
    //   const vizUrl = tableauViewURL;
    //   // 'https://public.tableau.com/views/VacationHome/VacationHome?:embed=y&:display_count=yes';

    //   const tableauOptions = {
    //     hideTabs: true,
    //     width: '700px',
    //     height: '800px',
    //     onFirstInteractive: () => {
    //       const sheet = this.viz
    //         .getWorkbook()
    //         .getActiveSheet()
    //         .getWorksheets()
    //         .get('Table');
    //       console.log('sheet: ', sheet);
    //       const options = {
    //         ignoreAliases: false,
    //         ignoreSelection: false,
    //         includeAllColumns: false,
    //       };
    //       sheet.getUnderlyingDataAsync(options).then((t) => {
    //         const tableauData = t.getData();
    //         let data = [];
    //         const pointCount = tableauData.length;
    //         for (let a = 0; a < pointCount; a++) {
    //           data = data.concat({
    //             x: tableauData[a][0].value,
    //             y: Math.round(tableauData[a][3].value, 2),
    //           });
    //         }
    //       });
    //     },
    //   };

    //   this.viz = new window.tableau.Viz(this.container, vizUrl, tableauOptions);
    // },
  },
};
</script>
<style lang="scss" scoped>
.bdc_main_wrap.whero {
  margin-top: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.body-content.fullmax .thw_content {
  max-width: 1600px;
}
.thw_content {
  max-width: 1270px;
  margin: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 0px;
  position: relative;
  z-index: 11;
}
.sopened .thw_content {
  max-width: 1160px;
}
.internal-sub-nav {
  display: inline-block;
  float: none;
  margin: 11px 20px 31px 5px;
  display: block;
}
.isn-item {
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarydark2);
  position: relative;
  cursor: pointer;
}

.isn-item.active {
  color: var(--primarydark);
}

.isn-item.active::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarycolor);
}

.isn-item:hover {
  color: var(--primarydark);
}

.isn-item:hover::before {
  content: '';
  width: 100%;
  height: 2px;
  margin-top: 25px;
  position: absolute;
  background-color: var(--primarydark2);
}
@media (max-width: 750px) {
  .bdc_top_panel_wrap {
    flex-wrap: wrap !important;
  }
}
.bdc_top_panel_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3;
}
</style>
