<template>
  <div>
    <!-- Success and Error Message Slot -->
    <div v-if="showMessage" :class="['message', messageType]" class="message-container">
      <slot name="message">{{ message }}</slot>
    </div>
    <div class="grid-container">
      <!-- Column 1: Template, Date Range, Media, and Interval -->
      <div class="grid-item" :class="{ disabled: isApiCallsLoading }">
        <div class="section">
          <div class="filter">
            <div class="filter-ttl"><span>TEMPLATE</span></div>
            <div class="filter-group-report">
              <div class="flp-controls">
                <b-loading-spinner2 v-if="isApiCallsLoading" :width="20" />
                <b-select
                  v-else
                  class="b-select"
                  :options="filters.templateOptions"
                  :selected="filters.template"
                  @change="onSelectTemplate"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter">
            <div class="filter-ttl"><span>DATE RANGE</span></div>
            <div class="filter-group-report">
              <div class="pan-content-top pan1-content-top">
                <b-datepicker
                  :key="dateKey"
                  :week1="[dates.startDate, dates.endDate]"
                  :campaign-options="campaignOptions"
                  :active-solution-id="activeSolutionId"
                  :hide-compare-with="true"
                  :on-reports-builder="true"
                  @change="bDatepickerChanges"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter media-settings">
            <div class="filter-ttl">
              <span>MEDIA</span>
            </div>
            <div class="filter-group-report mediaFilters">
              <div class="campaign-opts">
                <dropdown-checkbox-search
                  key="campaign"
                  name="campaign"
                  label="Search..."
                  :options="filters.campaignOptions"
                  :fancy="true"
                  :multiple="true"
                  :campaign-options-changed="campaignOptionsChanged"
                  label-key="value"
                  title=""
                  header="Campaigns"
                  component-id="ioOptionDropdown"
                  :allow-multi-select="true"
                  :show-all="false"
                  :value="selectedFilters.campaignOptions ? selectedFilters.campaignOptions : []"
                  original-component="dropdown"
                  @input="(val) => setSelectedFilters('campaignOptions', val)"
                />
              </div>
              <b-select-checkbox
                :options="filters?.adGroupOptions || adGroupOptions"
                title=""
                header="Ad Group"
                class="ad-group-select"
                :disabled="true"
                :show-all="filters?.adGroupOptions?.length > 1 || adGroupOptions.length > 1"
              />
              <div class="fp-text-link view-pop-fltr" :class="{ disabled: isAdGroupLoading }">
                <p @click="toggleAdvanceFilterModal">Advanced Filters</p>
                <span
                  >{{ appliedFilterCount > 0 ? `(${appliedFilterCount} Applied)` : '' }}
                  <font-awesome-icon
                    v-if="!isAdGroupLoading"
                    :icon="['far', 'times']"
                    class="advance-filter-clear-icon"
                    @click="onClearAdvanceFilters"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="filter aggregation">
            <div class="filter-ttl">
              <span>INTERVAL</span>
              <span v-if="singleSelectModeDimension">
                <font-awesome-icon
                  :icon="['fas', 'info-circle']"
                  class="tooltip-icon"
                  @mouseenter="showIntervalTooltip = true"
                  @mouseleave="showIntervalTooltip = false"
                />
              </span>
            </div>
            <div
              class="filter-group-report"
              :class="{ disabled: disableInterval || singleSelectModeDimension }"
            >
              <b-select
                class="aggregation-type"
                :options="filters.intervalOptions"
                :selected="filters.interval"
                @change="onSelectInterval"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Column 2: Attribution Settings, Methodology, Conversion Window -->
      <div class="grid-item">
        <div class="section">
          <div class="filter method-settings">
            <div class="filter-ttl"><span>ATTRIBUTION SETTINGS</span></div>
            <div class="filter-group-report">
              <b-radio-tab-group
                :initial-group="filters.totalOrUniqueRadioOptions || totalOrUniqueRadioOptions"
                :is-mni-account="isMniAccount"
                class="tot-uniq"
              />
            </div>
            <div class="filter-ttl"><span>Methodology</span></div>
            <div class="filter-group-report">
              <b-select
                class="media-select"
                :options="filters.methodologyOptions"
                :selected="filters.methodology"
                :disabled="true"
                @change="onSelectMethodology"
              />
              <div class="conv-content">
                <div class="filter-ttl"><span>Lookback Window</span></div>
                <div class="conversion-opt-cont">
                  <div class="opt-b1" data-children-count="1">
                    <input
                      v-model="filters.conversionWindowDisplay"
                      type="text"
                      name="conversion-window"
                      class="flp-input input-conv"
                      @keypress="onlyNumber"
                      @input="validateConversionWindow"
                      @blur="handleBlur"
                      @paste="handlePaste"
                    />
                  </div>
                  <div class="opt-b2">
                    <b-checkbox
                      label="Days"
                      name="Days"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'DAYS'"
                      @change="updateConversionWindowUnit"
                    />
                    <b-checkbox
                      label="Hours"
                      name="Hours"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'HOURS'"
                      @change="updateConversionWindowUnit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 3: Dimensions -->
      <div class="grid-item">
        <div class="section">
          <div class="filter checkbox-select">
            <div class="filter-ttl">
              <span>DIMENSIONS</span>
              <span v-if="singleSelectModeDimension">
                <font-awesome-icon
                  :icon="['fas', 'info-circle']"
                  class="tooltip-icon"
                  @mouseenter="showTooltip = true"
                  @mouseleave="showTooltip = false"
                />
              </span>
            </div>
            <!-- Dynamic Options -->
            <div
              v-for="option in reportDimensionsOptions"
              :key="option.id"
              :class="{
                disabled: disableAllDimensions,
              }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <input
                :id="option.id"
                v-model="selectedDimensionsOptions"
                class="conditions-check"
                type="checkbox"
                :value="option.id"
                :disabled="disableAllDimensions"
                @change="handleDimensionChange(option.id)"
              />
              <label :for="option.id">{{ option.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 4: Metrics -->
      <div class="grid-item metrics-opts">
        <div class="section">
          <div class="metrics-label"><span>METRICS</span></div>
          <div class="filter checkbox-select">
            <!-- SELECT ALL Option -->
            <div
              class="checkbox-select__check-wrapp dimension-item"
              :class="{ disabled: disabledAllMetrics }"
            >
              <input
                id="select-all-metrics"
                v-model="selectAllMetrics"
                type="checkbox"
                class="conditions-check"
                :disabled="disabledAllMetrics"
                @change="toggleSelectAllMetrics"
              />
              <label for="select-all-metrics">SELECT ALL</label>
            </div>
            <!-- Dynamic Options -->
            <div
              v-for="option in reportMetricsOptions"
              :key="option.key"
              :class="{ disabled: disabledAllMetrics }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <input
                :id="option.key"
                v-model="selectedMetricsOptions"
                type="checkbox"
                class="conditions-check"
                :value="option.key"
                :disabled="disabledAllMetrics"
                @change="handleMetricsChange(option.key)"
              />
              <label :for="option.key">{{
                option.value === 'Checkout Conversion Rate'
                  ? 'Conversions Conversion Rate'
                  : option.value === 'Checkout CPA'
                  ? 'Conversions CPA'
                  : option.value === 'Checkout Performance Multiplier'
                  ? 'Conversions Performance Multiplier'
                  : option.value === 'Checkouts'
                  ? 'Conversions'
                  : option.value === 'Checkouts Time to Convert'
                  ? 'Conversions Time to Convert'
                  : option.value === 'Checkouts Total Exposures'
                  ? 'Conversions Total Exposures'
                  : option.value
              }}</label>
            </div>
            <hr />
            <div
              v-for="(event, index) in eventOptions"
              :key="`event-${index}`"
              :class="{
                disabled:
                  disabledAllEvents ||
                  (['Clicks', 'Completions', 'Video Completions'].includes(event.event) &&
                    ['all_touch', 'last_touch'].includes(filters.template)),
              }"
              class="checkbox-select__check-wrapp dimension-item"
            >
              <!-- Main Event Checkbox -->
              <input
                :id="`event-${index}`"
                v-model="event.selected"
                type="checkbox"
                class="conditions-check"
                :value="event.category"
                :disabled="
                  (hasOnlySingleEventSelected && event.selected) ||
                    disabledAllEvents ||
                    (['Clicks', 'Completions'].includes(event.event) &&
                      ['all_touch', 'last_touch'].includes(filters.template))
                "
                :readonly="singleSelectMode && event.selected"
                @change="toggleMainCategory(index)"
              />
              <label :for="`event-${index}`">
                {{
                  event.event === 'Video Completions'
                    ? 'Completions'
                    : event.event === 'Checkouts'
                    ? 'Conversions'
                    : event.event
                }}
                <span v-if="event.numSubCategories && event.children.length > 0"
                  >({{ event.numSubCategories }} Subcategories)</span
                >
              </label>

              <!-- Subcategories -->
              <div
                v-if="event.selected && event.children && event.children.length"
                class="subcategory-container"
              >
                <div
                  v-for="(subCategory, subIndex) in event.children"
                  :key="`event-${index}-subCategory-${subIndex}`"
                  class="checkbox-select__check-wrapp dimension-item subcategory-item"
                >
                  <input
                    :id="`event-${index}-subCategory-${subIndex}`"
                    v-model="subCategory.selected"
                    type="checkbox"
                    class="conditions-check"
                    :value="subCategory.category"
                    @change="resetSubCategories"
                  />
                  <label :for="`event-${index}-subCategory-${subIndex}`">{{
                    subCategory.event
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Column 5: Delivery -->
      <DeliveryGrid
        :run-now="runNow"
        :save-template="saveTemplate"
        :report-name="reportName"
        :is-valid-report-name="isValidReportName"
        :button-label="buttonLabel"
        :disable-save-report-template="disableSaveReportTemplate"
        @run-now-changed="handleRunNowChange"
        @save-template-changed="handleSaveTemplateChange"
        @report-name-changed="trimReportName"
        @run-or-save-report="runOrSaveReport"
      />
    </div>
    <div v-if="showTooltip" class="custom-dimension-tooltip">
      {{ infoText }}
    </div>
    <div v-if="showIntervalTooltip" class="custom-interval-tooltip">
      {{ infoIntervalText }}
    </div>
  </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';
import fetchIosMethodMixin from '@/components/mixins/fetch-ios-method-mixin';
import fetchCampaignsMethodMixin from '@/components/mixins/fetch-campaigns-method-mixin';
import fetchEventsMethodMixin from '@/components/mixins/fetch-events-method-mixin';
import gaEventsMixin from '@/components/mixins/ga-events-mixin';
import BDatepicker from '@/components/elements/b-datepicker.vue';
import BSelectCheckbox from '@/components/elements/b-select-checkbox.vue';
import BSelect from '@/components/elements/b-select.vue';
import BRadioTabGroup from '@/components/elements/b-radio-tab-group.vue';
import BCheckbox from '@/components/elements/b-checkbox.vue';
import DropdownCheckboxSearch from '@/components/elements/b-auto-complete-dropdown3.vue';
import {
  ACCOUNTS_TO_SHOW,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  REPORT_BUILDER_DISABLED_METRICS,
  REPORTS_NEW_BUILDER_METRIC_OPTIONS,
  NEW_REPORT_OPTIONS,
  ERROR_MESSAGE_FETCH_TEMPLATE,
  ERROR_MESSAGE_DELIVERY_SELECTION,
  ERROR_MESSAGE_NAME_REQUIRED,
} from '@/constant';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import {
  convertEpochToNYTimezone,
  generateFlipDefaultDates,
  getCampaignDateRange,
  offsetDate,
} from '@/util/utility-functions';
import axios from 'axios';
import appConsoleDataLayer from '@/api/app-console-data';
import bLoadingSpinner2 from '@/components/elements/b-loading-spinner2.vue';
import { formatDateForAPI } from '@/util/apiDateFormat';
import config from '~/config';
import DeliveryGrid from './delivery-grid.vue';
import * as util from '~/util/utility-functions';

export default {
  name: 'ReportBuilder',
  components: {
    BDatepicker,
    BSelectCheckbox,
    BSelect,
    BRadioTabGroup,
    BCheckbox,
    DropdownCheckboxSearch,
    DeliveryGrid,
    bLoadingSpinner2,
  },
  mixins: [fetchIosMethodMixin, fetchCampaignsMethodMixin, fetchEventsMethodMixin, gaEventsMixin],
  props: {
    activeSolutionId: {
      type: Number,
      required: false,
      default: 8, // mni
    },
  },
  data() {
    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'ioOptions',
      'keywordOptions',
      'publisherOptions',
    ];
    return {
      config,
      filters: this.genFilters(),
      initialFilters: {},
      isAdGroupLoading: false,
      reportDimensionsOptions: [],
      selectedDimensionsOptions: ['creative'],
      disableAllDimensions: false,
      reportMetricsOptions: [],
      selectedMetricsOptions: [],
      disabledAllMetrics: false,
      selectAllMetrics: false,
      disabledAllEvents: false,
      singleSelectMode: false,
      singleSelectModeDimension: false,
      selectedReportType: 'performance',
      selectedReportKind: 'creative',
      allEventPerformance: false,
      runNow: false,
      saveTemplate: true,
      reportName: '',
      buttonLabel: 'RUN REPORT',
      showTooltip: false,
      showIntervalTooltip: false,
      disableSaveReportTemplate: false,
      exportResults: false,
      scheduleReport: true,
      reportFrequency: 'Monthly',
      emailRecipients: '',
      campaignOptionsChanged: false,
      selectedFilters: this.getSelectedFilters(this.genFilters(), filterOptionsArray),
      dateKey: 0,
      isAllOrLastTouch: false,
      isInitialCampaignChange: true,
      isInitialFilterChange: true,
      isInitialConversionWindowChange: true,
      isInitialConversionUnitChange: true,
      isApiCallsLoading: false,
      startTime: null,
      retryTimeout: 20000,
      showMessage: false,
      message: '',
      messageType: 'success',
      selectedTemplateId: '',
      infoText:
        'Only single select is allowed when All Events and Performance is selected as metrics.',
      disableInterval: false,
      infoIntervalText:
        'Hourly and Daily interval are added as Dimension when All Events and Performance is selected as metrics.',
    };
  },
  computed: {
    account: get('common/account'),
    dates: get('dashboard/dates'),
    isMniAccount: get('common/isMniAccount'),
    mniTemplateId: get('common/mniTemplateId'),
    isViaAccount: get('common/isViaAccount'),
    eventOptions: get('dashboard/filters@eventOptions'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    publisherOptions: get('dashboard/filters@publisherOptions'),
    pixelOptions: get('dashboard/filters@pixelOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    keywordOptions: get('dashboard/filters@keywordOptions'),
    audienceOptions: get('dashboard/filters@audienceOptions'),
    creativeOptions: get('dashboard/filters@creativeOptions'),
    conversionWindow: get('dashboard/filters@conversionWindow'),
    advertiser: get('common/advertiser'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    ioOptions: get('dashboard/filters@ioOptions'),
    campaignOptions: get('dashboard/filters@campaignOptions'),
    universalPixelId: get('common/universalPixelId'),
    selectedExportTableRow: sync('common/selectedExportTableRow'),
    selectedEvent: get('dashboard/GET_SELECTED_ALL_EVENTS'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    advertiserId() {
      return this.advertiser ? this.advertiser.id : 0;
    },
    isValidReportName() {
      return this.reportName.trim().length > 0;
    },
    isSaveButtonDisabled() {
      return false;
    },
    isCreativeOnly() {
      return (
        this.selectedDimensionsOptions.length === 1 &&
        this.selectedDimensionsOptions.includes('creative')
      );
    },
    hasOnlySingleEventSelected() {
      return false; // Placeholder for future logic
    },
    appliedFilterCount() {
      const filters = this.$store.copy('dashboard/filters');
      let appliedCount = 0;
      const filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'creativeOptions',
        'audienceOptions',
        'keywordOptions',
      ];
      filterKeys.forEach((key) => {
        if (filters[key]?.filter((v) => v.checked)?.length > 0) {
          appliedCount += filters[key]?.filter((v) => v.checked)?.length ? 1 : 0;
        }
      });
      return appliedCount;
    },
    appliedFilterCountNew() {
      let appliedCount = 0;
      const filterUsed = this.filters;

      const filterKeys = [
        'campaignOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'audienceOptions',
        'creativeOptions',
        'publisherOptions',
      ];

      filterKeys.forEach((key) => {
        if (filterUsed[key]?.some((v) => v.checked)) {
          appliedCount += 1;
        }
      });
      return appliedCount;
    },
  },
  watch: {
    campaignOptions(n) {
      if (n) {
        this.filters.campaignOptions = JSON.parse(JSON.stringify(n));
        this.selectedFilters.campaignOptions = this.filters.campaignOptions.filter(
          (item) => item.checked
        );
      }
    },
    mediaTypeOptions(n) {
      if (n) {
        this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
      }
    },
    eventOptions: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n) {
          this.filters.eventOptions = JSON.parse(JSON.stringify(n));
          this.selectAllMetrics = false;
          this.setMetricOptsForEvents(this.reportMetricsOptions);
        }
      },
    },
    audienceOptions(n) {
      if (n) {
        this.filters.audienceOptions = JSON.parse(JSON.stringify(n));
      }
    },
    reportDimensionsOptions: {
      deep: true,
      async handler(n, o) {
        if (util.deepEqual(n, o)) return;
        if (n) {
          this.reportDimensionsOptions = n.sort((a, b) => {
            if (a.id === 'overall') return -1;
            if (b.id === 'overall') return 1;
            return a.name.localeCompare(b.name);
          });
        }
      },
    },
    reportMetricsOptions: {
      deep: true,
      immediate: true,
      async handler(n, o) {
        if (util.deepEqual(n, o)) {
          return;
        }
        if (n) {
          this.reportMetricsOptions = n.sort((a, b) => {
            if (a.value === 'All Events and Performance') return -1;
            if (b.value === 'All Events and Performance') return 1;
            return a.value.localeCompare(b.value);
          });
        }
      },
    },
  },
  async created() {
    EventBus.$on('advance-filter-on-apply', this.onAdvanceFilterApply);
    this.reportDimensionsOptions = this.getReportDimensionsOptions();
    this.setMetricOpts(true);
    this.setMetricOptsForEvents(this.reportMetricsOptions);
    this.initializeMetricsSelection();
  },
  beforeDestroy() {
    EventBus.$off('advance-filter-on-apply', this.onAdvanceFilterApply);
    // delete initial storage values
    localStorage.removeItem('initialReportBuilderFilters');
  },
  async mounted() {
    this.$store.set('dashboard/filters@template', 'new');
    this.$store.dispatch('dashboard/resetEventOptions');

    this.mniAccount = [MNI_ACCOUNT_ID, MNI_DEMO_ACCOUNT_ID].includes(this.account?.id);
    this.$store.dispatch('common/setIsMniAccount', this.mniAccount);

    const filterOptionsArray = [
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'ioOptions',
      'keywordOptions',
      'publisherOptions',
    ];
    this.selectedFilters = this.getSelectedFilters(this.genFilters(), filterOptionsArray);

    this.setMetricOptsForEvents(this.reportMetricsOptions);
    this.checkIfAllNecessaryDataIsAvailable();
  },
  methods: {
    showSuccessMessage(msg) {
      this.message = msg;
      this.messageType = 'success';
      this.displayMessage();
    },
    showErrorMessage(msg) {
      this.message = msg;
      this.messageType = 'error';
      this.displayMessage();
    },
    displayMessage() {
      this.showMessage = true;
      setTimeout(() => {
        this.showMessage = false;
      }, 5000);
    },
    checkIfAllNecessaryDataIsAvailable() {
      if (this.startTime === null) {
        this.startTime = Date.now();
      }

      this.$nextTick(() => {
        if (
          EventBus.campaignsReady &&
          EventBus.mediaReady &&
          EventBus.creativesReady &&
          EventBus.audienceReady
        ) {
          this.isApiCallsLoading = false;

          this.getUserSavedReports(this.advertiser?.id);
          this.filters.template = 'new';
          this.filters = this.genFilters();

          const initialFilterFromStorage = localStorage.getItem('initialReportBuilderFilters');
          if (initialFilterFromStorage !== undefined && initialFilterFromStorage !== null) {
            this.initialFilters = JSON.parse(initialFilterFromStorage);
          } else {
            this.initialFilters = JSON.parse(JSON.stringify(this.genFilters()));
            localStorage.setItem(
              'initialReportBuilderFilters',
              JSON.stringify(this.initialFilters)
            );
          }

          this.resetToDefaultValues(this.initialFilters);
          this.$nextTick(() => {
            this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
          });
        } else if (Date.now() - this.startTime < this.retryTimeout) {
          this.isApiCallsLoading = true;
          setTimeout(() => this.checkIfAllNecessaryDataIsAvailable(), 300);
        } else {
          this.isApiCallsLoading = false;
          console.error('Retry time exceeded 20 seconds. Some data might still be missing.');
        }
      });
    },
    genFilters() {
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      const showUniques = this.$store.copy('dashboard/filters@showUniques');
      if (showUniques) {
        totalOrUniqueRadioOptions[1].active = true;
        totalOrUniqueRadioOptions[0].active = false;
      }
      const intervalOptions = this.$store.copy('dashboard/filters@intervalOptions');
      const interval = this.$store.copy('dashboard/filters@interval');
      intervalOptions.forEach((a) => {
        a.active = interval === a.id;
      });

      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      const methodology = this.$store.copy('dashboard/filters@methodology');
      methodologyOptions.forEach((mo) => {
        mo.active = methodology === mo.id;
      });
      const campaignOptions = this.$store.copy('dashboard/filters@campaignOptions');
      const audienceOpts = this.$store.copy('dashboard/filters@audienceOptions');
      const mediaTypeOpts = this.$store.copy('dashboard/filters@mediaTypeOptions');
      return {
        templateOptions: this.$store.copy('dashboard/filters@templateOptions'),
        template: this.$store.copy('dashboard/filters@template'),
        intervalOptions,
        interval,
        showUniques,
        methodology,
        methodologyOptions,
        conversionWindowUnit: this.$store.copy('dashboard/filters@conversionWindowUnit'),
        campaignOptions,
        conversionWindowDisplay: this.$store.copy('dashboard/filters@conversionWindowDisplay'),
        mediaTypeOpts,
        audienceOpts,
        creativeOptions: this.$store.copy('dashboard/filters@creativeOptions'),
        publisherOptions: this.$store.copy('dashboard/filters@publisherOptions'),
        eventOptions: this.$store.copy('dashboard/filters@eventOptions'),
      };
    },
    onAdvanceFilterApply(applyFilters) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }
      this.filters.campaignOptions = applyFilters.campaignOptions;
      this.filters.adGroupOptions = applyFilters.adGroupOptions;
      this.filters.mediaTypeOptions = applyFilters.mediaTypeOptions;
      this.filters.creativeOptions = applyFilters.creativeOptions;
      this.filters.audienceOptions = applyFilters.audienceOptions;
      this.filters.publisherOptions = applyFilters.publisherOptions;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    async setDefaultDates() {
      let computedDate = await get('dashboard/dates');
      const { startDate, endDate, dateRangeOption, compareWith } = generateFlipDefaultDates();
      computedDate = { ...this.dates, startDate, endDate, dateRangeOption, compareWith };
      this.$store.set('dashboard/dates', {
        ...this.dates,
        ...computedDate,
      });
      this.dateKey += 1;
    },
    resetToDefaultValues(initialFilters) {
      this.setDefaultDates();
      this.filters.conversionWindowUnit = initialFilters?.conversionWindowUnit;
      this.filters.conversionWindowDisplay = initialFilters?.conversionWindowDisplay;
      this.filters.interval = initialFilters?.interval;
      this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);
      this.$store.dispatch('dashboard/resetToDefaultAPFilters');
      this.resetEventSelection();
      this.resetCampaignSelection(initialFilters);
    },
    resetAdvanceFilterOptions(initialFilters) {
      this.$nextTick(() => {
        this.filters.campaignOptions = initialFilters.campaignOptions;
        this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);
        this.filters.mediaTypeOptions = initialFilters.mediaTypeOptions;
        this.$store.set('dashboard/filters@mediaTypeOptions', initialFilters?.mediaTypeOpts);
        this.filters.creativeOptions = initialFilters.creativeOptions;
        this.$store.set('dashboard/filters@creativeOptions', initialFilters?.creativeOptions);
        this.filters.audienceOptions = initialFilters.audienceOpts;
        this.$store.set('dashboard/filters@audienceOptions', initialFilters?.audienceOpts);
      });
    },
    resetCampaignSelection(initialFilters) {
      this.$nextTick(() => {
        this.filters.campaignOptions = initialFilters.campaignOptions;
        this.$store.set('dashboard/filters@campaignOptions', initialFilters?.campaignOptions);
      });
    },
    resetEventSelection() {
      this.eventOptions.forEach((event, index) => {
        event.selected = index === 0;
        if (event.children && event.children.length) {
          event.children.forEach((subCategory) => {
            subCategory.selected = index === 0;
          });
        }
      });
    },
    onSelectTemplate(template) {
      this.disableInterval = false;
      if (template === 'all_touch' || template === 'last_touch') {
        this.reportName = '';
        this.selectedTemplateId = '';

        // disable dimensions
        this.selectedDimensionsOptions = [];
        this.disableAllDimensions = true;

        // disable metrics
        this.selectedMetricsOptions = [];
        this.selectAllMetrics = false;
        this.disabledAllMetrics = true;
        this.disabledAllEvents = false;

        // reset to default values
        this.resetToDefaultValues(this.initialFilters);

        this.singleSelectMode = true;

        // disable save template checkbox
        this.disableSaveReportTemplate = true;
        this.runNow = true;
        this.handleRunNowChange(true);
        this.disableInterval = true;
      } else if (template === 'new') {
        this.isInitialCampaignChange = true;
        this.isInitialFilterChange = true;
        this.isInitialConversionWindowChange = true;
        this.isInitialConversionUnitChange = true;

        this.reportName = '';
        this.selectedTemplateId = '';
        this.disableAllDimensions = false;
        this.selectedDimensionsOptions = ['creative'];
        this.selectedReportKind = 'creative';
        this.setMetricOpts();
        this.runNow = false;
        this.disabledAllMetrics = false;
        this.disabledAllEvents = false;
        this.selectAllMetrics = true;
        this.singleSelectMode = false;
        this.disableSaveReportTemplate = false;
        this.resetToDefaultValues(this.initialFilters);
        this.setMetricOptsForEvents(this.reportMetricsOptions);
        this.toggleSelectAllMetrics();
      } else {
        this.selectAllMetrics = false;
        this.disableAllDimensions = false;
        this.disabledAllMetrics = false;
        this.disabledAllEvents = false;
        this.disableSaveReportTemplate = false;
        this.singleSelectMode = false;
        this.runNow = true;

        this.selectedTemplateId = template;

        const selectedOption = this.filters.templateOptions.find(
          (option) => option.id === template
        );
        if (selectedOption && selectedOption.filters) {
          this.applyTemplateFilters(selectedOption.filters);
        }
        this.$nextTick(() => {
          this.reportName = selectedOption.name;
        });
      }
      this.$nextTick(() => {
        this.filters.template = template;
        this.$store.set('dashboard/filters@template', template);
      });
    },
    onSelectInterval(interval) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }
      this.filters.interval = interval;
    },
    toggleAdvanceFilterModal() {
      this.filters.isNewReportBuilder = true;
      EventBus.$emit('show-advance-filter', this.filters);
    },
    onClearAdvanceFilters() {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }
      this.resetAdvanceFilterOptions(this.initialFilters);
    },
    getReportDimensionsOptions() {
      let dimensions = [...NEW_REPORT_OPTIONS];
      if (!ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
        dimensions = NEW_REPORT_OPTIONS.filter((item) => item.id !== 'channel');
      }
      if (this.isMniAccount) {
        dimensions = NEW_REPORT_OPTIONS.filter((item) => item.id !== 'audience');
        dimensions.splice(5, 0, { id: 'audience', name: 'Placement', type: 'performance' });
      }
      return dimensions;
    },
    setMetricOptsForEvents(reportMetricsOptions) {
      let opts = [...reportMetricsOptions]; // Create a shallow copy of the array to avoid direct mutation

      const hasVCR = this.eventOptions.some((event) => event.category === 'vcr');
      const hasCTR = this.eventOptions.some((event) => event.category === 'ctr');

      opts = opts.filter((metric) => {
        if ((hasVCR && metric.key === 'completions') || (hasCTR && metric.key === 'clicks')) {
          return false; // Exclude this item
        }
        return true; // Keep other items
      });

      let resetMetrics = true;
      this.selectedEvent.forEach((event) => {
        if (
          event.eventLabel === 'Leads' ||
          event.eventLabel === 'Checkouts' ||
          event.eventLabel === 'Page Visits'
        ) {
          resetMetrics = false;
        }
      });

      if (resetMetrics) {
        // Uncomment if needed
      }

      if (this.selectedEvent.length === 0) {
        opts = opts.filter(
          (item) =>
            ![
              'leadTimeToConvert',
              'leadTotalExposures',
              // 'lead',
              'leadConversionRate',
              'leadCpa',
              'leadPm',
              'checkoutTimeToConvert',
              'checkoutTotalExposures',
              // 'checkout',
              'checkoutConversionRate',
              'checkoutCpa',
              'checkoutPm',
              'pageVisitTimeToConvert',
              'pageVisitTotalExposures',
              // 'pageVisit',
              'pageVisitConversionRate',
              'pageVisitCpa',
              'pageVisitPm',
              // location visit
              'locationVisitTimeToConvert',
              'locationVisitTotalExposures',
              'locationVisitConversionRate',
              'locationVisitCpa',
              'locationVisitPm',
              // Activity
              'activityTimeToConvert',
              'activityTotalExposures',
              'activityConversionRate',
              'activityCpa',
              'activityPm',
            ].includes(item.key)
        );
      } else if (!resetMetrics) {
        opts = opts.filter((item) => !['conversionRate', 'cpa', 'pm'].includes(item.key));
      }

      // Loop through selectedEvent and add metrics if necessary
      this.selectedEvent.forEach((event) => {
        if (event.eventLabel === 'Leads') {
          // Add metrics related to time to convert and total exposures when overall is selected
          if (
            !opts.some((item) => item.key === 'leadTimeToConvert') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'leadTimeToConvert',
              name: 'Leads Time to Convert',
              value: 'Leads Time to Convert',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (
            !opts.some((item) => item.key === 'leadTotalExposures') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'leadTotalExposures',
              name: 'Leads Total Exposures',
              value: 'Leads Total Exposures',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'leadConversionRate')) {
            opts.push({
              key: 'leadConversionRate',
              name: '(%)Lead Conversion Rate',
              value: 'Lead Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'leadCpa')) {
            opts.push({
              key: 'leadCpa',
              name: 'Lead CPA',
              value: 'Lead CPA',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'leadPm')) {
            opts.push({
              key: 'leadPm',
              name: 'Lead Performance Multiplier',
              value: 'Lead Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }
        } else if (event.eventLabel === 'Checkouts') {
          // Add metrics related to time to convert and total exposures when overall is selected
          if (
            !opts.some((item) => item.key === 'checkoutTimeToConvert') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'checkoutTimeToConvert',
              name: 'Checkouts Time to Convert',
              value: 'Checkouts Time to Convert',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (
            !opts.some((item) => item.key === 'checkoutTotalExposures') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'checkoutTotalExposures',
              name: 'Checkouts Total Exposures',
              value: 'Checkouts Total Exposures',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'checkoutConversionRate')) {
            opts.push({
              key: 'checkoutConversionRate',
              name: '(%)Checkout Conversion Rate',
              value: 'Checkout Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'checkoutCpa')) {
            opts.push({
              key: 'checkoutCpa',
              name: 'Checkout CPA',
              value: 'Checkout CPA',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'checkoutPm')) {
            opts.push({
              key: 'checkoutPm',
              name: 'Checkout Performance Multiplier',
              value: 'Checkout Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }
        } else if (event.eventLabel === 'Page Visits') {
          // Add metrics related to time to convert and total exposures when overall is selected
          if (
            !opts.some((item) => item.key === 'pageVisitTimeToConvert') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'pageVisitTimeToConvert',
              name: 'Page Visits Time to Convert',
              value: 'Page Visits Time to Convert',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (
            !opts.some((item) => item.key === 'pageVisitTotalExposures') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'pageVisitTotalExposures',
              name: 'Page Visits Total Exposures',
              value: 'Page Visits Total Exposures',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'pageVisitConversionRate')) {
            opts.push({
              key: 'pageVisitConversionRate',
              name: '(%)Page Visit Conversion Rate',
              value: 'Page Visit Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'pageVisitCpa')) {
            opts.push({
              key: 'pageVisitCpa',
              name: 'Page Visit CPA',
              value: 'Page Visit CPA',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'pageVisitPm')) {
            opts.push({
              key: 'pageVisitPm',
              name: 'Page Visit Performance Multiplier',
              value: 'Page Visit Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }
        } else if (event.eventLabel === 'Revenue') {
          if (!opts.some((item) => item.key === 'roas')) {
            opts.push({
              key: 'roas',
              name: '(%)ROAS',
              value: 'ROAS',
              formatter: { type: 'number', format: '0' },
              checked: true,
            });
          }
        } else if (event.eventLabel === 'Location Visits') {
          // Add metrics related to time to convert and total exposures when overall is selected
          if (
            !opts.some((item) => item.key === 'locationVisitTimeToConvert') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'locationVisitTimeToConvert',
              name: 'Location Visits Time to Convert',
              value: 'Location Visits Time to Convert',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (
            !opts.some((item) => item.key === 'locationVisitTotalExposures') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'locationVisitTotalExposures',
              name: 'Location Visits Total Exposures',
              value: 'Location Visits Total Exposures',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'locationVisitConversionRate')) {
            opts.push({
              key: 'locationVisitConversionRate',
              name: '(%)Location Visits Conversion Rate',
              value: 'Location Visits Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'locationVisitCpa')) {
            opts.push({
              key: 'locationVisitCpa',
              name: 'Location Visits CPA',
              value: 'Location Visits CPA',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'locationVisitPm')) {
            opts.push({
              key: 'locationVisitPm',
              name: 'Location Visits Performance Multiplier',
              value: 'Location Visits Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }
        } else if (event.eventLabel === 'Activities') {
          // Add metrics related to time to convert and total exposures when overall is selected
          if (
            !opts.some((item) => item.key === 'activityTimeToConvert') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'activityTimeToConvert',
              name: 'Activities Time to Convert',
              value: 'Activities Time to Convert',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (
            !opts.some((item) => item.key === 'activityTotalExposures') &&
            this.selectedReportKind === 'overall'
          ) {
            opts.push({
              key: 'activityTotalExposures',
              name: 'Activities Total Exposures',
              value: 'Activities Total Exposures',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'activityConversionRate')) {
            opts.push({
              key: 'activityConversionRate',
              name: '(%)Activities Conversion Rate',
              value: 'Activities Conversion Rate',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'activityCpa')) {
            opts.push({
              key: 'activityCpa',
              name: 'Activities CPA',
              value: 'Activities CPA',
              formatter: { type: 'number', format: '0.00' },
              checked: true,
            });
          }

          if (!opts.some((item) => item.key === 'activityPm')) {
            opts.push({
              key: 'activityPm',
              name: 'Activities Performance Multiplier',
              value: 'Activities Performance Multiplier',
              formatter: { type: 'number', format: '0,0.[00]' },
              checked: true,
            });
          }
        }
      });

      // Now check for any events that were removed and remove their metrics
      if (!this.selectedEvent.some((event) => event.eventLabel === 'Leads')) {
        opts = opts.filter(
          (item) =>
            ![
              'leadTimeToConvert',
              'leadTotalExposures',
              // 'lead',
              'leadConversionRate',
              'leadCpa',
              'leadPm',
            ].includes(item.key)
        );

        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) =>
            ![
              'leadTimeToConvert',
              'leadTotalExposures',
              'leadConversionRate',
              'leadCpa',
              'leadPm',
            ].includes(item)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Checkouts')) {
        opts = opts.filter(
          (item) =>
            ![
              'checkoutTimeToConvert',
              'checkoutTotalExposures',
              'checkoutConversionRate',
              'checkoutCpa',
              'checkoutPm',
            ].includes(item.key)
        );
        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) =>
            ![
              'checkoutTimeToConvert',
              'checkoutTotalExposures',
              'checkoutConversionRate',
              'checkoutCpa',
              'checkoutPm',
            ].includes(item)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Page Visits')) {
        opts = opts.filter(
          (item) =>
            ![
              'pageVisitTimeToConvert',
              'pageVisitTotalExposures',
              'pageVisitConversionRate',
              'pageVisitCpa',
              'pageVisitPm',
            ].includes(item.key)
        );
        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) =>
            ![
              'pageVisitTimeToConvert',
              'pageVisitTotalExposures',
              'pageVisitConversionRate',
              'pageVisitCpa',
              'pageVisitPm',
            ].includes(item)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Revenue')) {
        opts = opts.filter((item) => !['revenue', 'roas'].includes(item.key));

        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) => !['revenue', 'roas'].includes(item)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Location Visits')) {
        opts = opts.filter(
          (item) =>
            ![
              'locationVisitTimeToConvert',
              'locationVisitTotalExposures',
              'locationVisitConversionRate',
              'locationVisitCpa',
              'locationVisitPm',
            ].includes(item.key)
        );
        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) =>
            ![
              'locationVisitTimeToConvert',
              'locationVisitTotalExposures',
              'locationVisitConversionRate',
              'locationVisitCpa',
              'locationVisitPm',
            ].includes(item)
        );
      }

      if (!this.selectedEvent.some((event) => event.eventLabel === 'Activities')) {
        opts = opts.filter(
          (item) =>
            ![
              'activityTimeToConvert',
              'activityTotalExposures',
              'activityConversionRate',
              'activityCpa',
              'activityPm',
            ].includes(item.key)
        );
        this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
          (item) =>
            ![
              'activityTimeToConvert',
              'activityTotalExposures',
              'activityConversionRate',
              'activityCpa',
              'activityPm',
            ].includes(item)
        );
      }

      // Assign the modified array back to reportMetricsOptions
      this.reportMetricsOptions = opts;
    },
    setMetricOpts(isMounted = false) {
      if (this.selectedExportTableRow) {
        this.selectedReportKind = this.selectedExportTableRow;
      }
      const key = `${this.selectedReportType}`;
      let opts = JSON.parse(JSON.stringify(REPORTS_NEW_BUILDER_METRIC_OPTIONS[key] || []));
      opts = opts.filter((opt) => opt.key !== 'incrementalReach');
      if (this.selectedEvent.isFalseEvent) {
        opts = opts.filter((opt) => !['checkouts', 'metric', 'pm'].includes(opt.key));
      }
      const disabledOptions = REPORT_BUILDER_DISABLED_METRICS[this.selectedReportKind] || [];
      const isAllEvnOptSelected = this.reportMetricsOptions.filter((op) => op.key === 'ep')?.[0]
        ?.checked;
      if (isAllEvnOptSelected && key !== 'rollup') {
        this.allEventPerformance = true;
        return;
      }
      opts = opts.map((item) => {
        if (item.key === 'videoCompletionRate') item.value = `(%)Completion Rate`;
        if (item.key === 'videoCompletions') item.value = `Completions`;
        return item;
      });
      opts.forEach((opt) => {
        const isOptionDisabled = disabledOptions.includes(opt.key);
        opt.checked = !isOptionDisabled;
        opt.disabled = isOptionDisabled;

        if (this.selectedExportTableRow) {
          if (['reach', 'frequency'].includes(opt.key)) {
            opt.checked = false;
          } else if (this.selectedEvent.isFalseEvent) {
            if (['videoCompletions', 'videoCompletionRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isVcrEvent;
            }
            if (['clicks', 'clickThroughRate'].includes(opt.key)) {
              opt.checked = this.selectedEvent.isCtrEvent;
            }
          } else if (
            ['videoCompletions', 'videoCompletionRate', 'clicks', 'clickThroughRate'].includes(
              opt.key
            )
          ) {
            opt.checked = false;
          }
        }
        if (key === 'performance' && opt.key === 'checkouts') {
          opt.key = this.selectedEvent.isRevenueEvent ? 'revenue' : 'checkouts';
          opt.name = this.selectedEvent.isRevenueEvent ? '($)Rev' : this.selectedEventLabel;
          opt.value = this.selectedEventLabel;
          opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0,0.00' : '0,0.[00]';
        } else if (key === 'performance' && opt.key === 'metric') {
          opt.checked =
            isMounted ||
            this.reportMetricsOptions.filter((oa) => oa.key === 'cpa' || oa.key === 'roas')?.[0]
              ?.checked;
          if (this.selectedEventLabel === 'Incremental Reach') {
            opt.key = 'incrementalReachPercentage';
            opt.name = 'Inc. Reach %';
            opt.formatter.format = '0';
          } else {
            const unit = this.selectedEvent.isRevenueEvent ? '%' : '$';
            opt.key = this.selectedEvent.isRevenueEvent ? 'roas' : 'cpa';
            opt.name = `(${unit})${this.selectedEvent.metric}`;
            opt.formatter.format = this.selectedEvent.isRevenueEvent ? '0' : '0,0.00';
          }
          opt.infinityKey = `${opt.key}Infinity`;
          opt.value = this.selectedEvent.metric;
        }
        if (opt.key === 'ep') {
          opt.checked = false;
        }
      });
      opts = opts.filter((opt) => opt.key !== 'incrementalReachPercentage');
      if (this.selectedEventLabel === 'Incremental Reach') {
        opts.forEach((op) => {
          if (op.key === 'incrementalReachPercentage') {
            op.value = 'Inc. Reach %';
            op.checked = true;
          }
          return op;
        });
      }

      this.reportMetricsOptions = opts.filter(
        (opt) => opt.value && opt.name !== 'Incremental Reach'
      );

      if (!isMounted) {
        this.setMetricOptsForEvents(this.reportMetricsOptions);
      }
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
    bDatepickerChanges(data) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      const datePickerPayload = {
        startDate: data.dates[0],
        endDate: data.dates[1],
        dateRangeOption: data.dateRangeOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
      this.$forceUpdate();
    },
    getSelectedFilters(filters, filterOptionsArray) {
      const selectFilterVals = {};
      filterOptionsArray?.forEach((fl) => {
        selectFilterVals[fl] = filters[fl]?.filter((f) => f.checked) || [];
      });
      return JSON.parse(JSON.stringify(selectFilterVals));
    },
    handleRunNowChange(val) {
      this.runNow = val;
    },
    handleSaveTemplateChange(val) {
      this.saveTemplate = val;
    },
    trimReportName(reportName) {
      this.reportName = reportName?.trim() || ''; // Trim leading and trailing spaces
      this.filters.template = 'new';
      this.$store.set('dashboard/filters@template', 'new');
      this.selectedTemplateId = '';
    },
    applyTemplateFilters(templateFilters) {
      this.selectedDimensionsOptions = templateFilters.dimensions;
      if (this.selectedDimensionsOptions.includes('publisher')) {
        this.selectedReportKind = 'publisher';
      } else if (this.selectedDimensionsOptions.includes('overall')) {
        this.selectedReportKind = 'overall';
      }
      this.setMetricOpts();

      this.selectedMetricsOptions = templateFilters.reportMetrics
        .filter((item) => item.event === false)
        .map((item) => item.metrics);

      if (this.selectedMetricsOptions.length === 1 && this.selectedMetricsOptions[0] === 'ep') {
        this.handleMetricsChange('ep');
      }

      if (
        this.reportMetricsOptions.length ===
        templateFilters.reportMetrics.filter((item) => item.event === false).length + 1
      ) {
        this.selectAllMetrics = true;
        this.toggleSelectAllMetrics();
      }

      this.eventOptions.forEach((event) => {
        const matchedEvent = templateFilters.reportMetrics.find((item) => {
          const formattedMetric = this.formatEventNamesReverse(item.metrics);
          if (
            (item.metrics === 'completions' || item.metrics === 'clicks') &&
            formattedMetric === event.event
          ) {
            // Allow item.event to be false for these specific cases
            return true;
          }

          // Default logic for other cases
          return formattedMetric === event.event && item.event === true;
        });

        if (matchedEvent) {
          event.selected = true;
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              const matchedSubCategory = matchedEvent.subCategory.find(
                (sub) => sub === subCategory.category
              );
              subCategory.selected = !!matchedSubCategory;
            });
          }
        } else {
          event.selected = false;
          if (event.children && event.children.length > 0) {
            event.children.forEach((subCategory) => {
              subCategory.selected = false;
            });
          }
        }
      });

      this.setMetricOptsForEvents(this.reportMetricsOptions);
      this.filters.conversionWindowDisplay = templateFilters.conversionWindow;
      this.filters.conversionWindowUnit = templateFilters.conversionWindowUnit;
      this.filters.interval = templateFilters.interval.toLowerCase();

      const campaignOpts = this.filters.campaignOptions || this.initialFilters?.campaignOptions;
      campaignOpts.forEach((campaign) => {
        campaign.checked = templateFilters.campaignIds?.includes(String(campaign.campaignId));
      });
      this.$store.set('dashboard/filters@campaignOptions', campaignOpts);

      let datePickerPayload = {};
      switch (templateFilters.dateRangeType) {
        case 'LAST_7_DAYS':
          datePickerPayload = {
            startDate: convertEpochToNYTimezone(offsetDate(8)),
            endDate: convertEpochToNYTimezone(offsetDate(2)),
            dateRangeOption: '6',
          };
          break;
        case 'LAST_2_WEEKS':
          datePickerPayload = {
            startDate: convertEpochToNYTimezone(offsetDate(15)),
            endDate: convertEpochToNYTimezone(offsetDate(2)),
            dateRangeOption: '14',
          };
          break;
        case 'LAST_30_DAYS':
          datePickerPayload = {
            startDate: convertEpochToNYTimezone(offsetDate(31)),
            endDate: convertEpochToNYTimezone(offsetDate(2)),
            dateRangeOption: '7',
          };
          break;
        case 'CAMPAIGN_TO_DATE': {
          const dateRange = getCampaignDateRange(campaignOpts, true);
          datePickerPayload = {
            startDate: convertEpochToNYTimezone(dateRange.startDate),
            endDate: convertEpochToNYTimezone(dateRange.endDate),
            dateRangeOption: '13',
          };
          break;
        }
        case 'CUSTOM':
          datePickerPayload = {
            startDate: templateFilters.startDate,
            endDate: templateFilters.endDate,
            dateRangeOption: '0',
          };
          break;
        default:
          break;
      }

      this.$store.set('dashboard/dates', datePickerPayload);
      this.dateKey += 1;

      const creativeOpts =
        [...this.filters.creativeOptions] || this.initialFilters?.creativeOptions;
      creativeOpts.forEach((creatives) => {
        creatives.checked = templateFilters.creatives?.includes(String(creatives.key));
      });
      this.$store.set('dashboard/filters@creativeOptions', creativeOpts);

      const mediaTypeOpts = this.filters.mediaTypeOptions || this.initialFilters?.mediaTypeOpts;
      mediaTypeOpts.forEach((media) => {
        media.checked = templateFilters.mediaTypes?.includes(String(media.value));
      });
      this.$store.set('dashboard/filters@mediaTypeOptions', mediaTypeOpts);

      const audienceOpts = this.filters.audienceOptions || this.initialFilters?.audienceOpts;
      audienceOpts.forEach((audience) => {
        audience.checked = templateFilters.audiences?.includes(String(audience.value));
      });
      this.$store.set('dashboard/filters@audienceOptions', audienceOpts);
    },
    formatEventNames(name) {
      const eventMap = {
        Leads: 'lead',
        Checkouts: 'checkout',
        Revenue: 'revenue',
        'Page Visits': 'pageVisit',
        'Video Completions': 'completions',
        Clicks: 'clicks',
        'Location Visits': 'locationVisit',
        Activities: 'activity',
      };
      return eventMap[name] || name;
    },
    formatEventNamesReverse(name) {
      const eventMap = {
        lead: 'Leads',
        checkout: 'Checkouts',
        revenue: 'Revenue',
        pageVisit: 'Page Visits',
        completions: 'Video Completions',
        clicks: 'Clicks',
        locationVisit: 'Location Visits',
        activity: 'Activities',
      };
      return eventMap[name] || name;
    },
    runOrSaveReport() {
      if (!this.saveTemplate && ['new'].includes(this.filters.template)) {
        this.reportName = '';
        this.selectedTemplateId = '';
      }
      // validate
      if (this.selectedDimensionsOptions.length < 1) {
        const errorMessage = 'Please select at least one dimension';
        this.showErrorMessage(errorMessage);
        return;
      }

      const transformedReportMetrics = this.selectedMetricsOptions.map((metric) => ({
        metrics: metric,
        event: false,
        subCategory: [],
      }));
      const transformedEventOptions = this.eventOptions
        .filter((option) => option.selected)
        .map((option) => ({
          metrics: this.formatEventNames(option.event),
          event: !(option.event === 'Video Completions' || option.event === 'Clicks'),
          subCategory:
            option.children?.length > 0
              ? option.children.filter((child) => child.selected).map((child) => child.category)
              : [],
        }));
      const mergedMetrics = transformedReportMetrics.concat(transformedEventOptions);

      const dates = this.$store.get('dashboard/dates');
      let dateRangeType = null;
      if (String(dates.dateRangeOption) === '6') {
        dateRangeType = 'LAST_7_DAYS';
      } else if (String(dates.dateRangeOption) === '14') {
        dateRangeType = 'LAST_2_WEEKS';
      } else if (String(dates.dateRangeOption) === '7') {
        dateRangeType = 'LAST_30_DAYS';
      } else if (String(dates.dateRangeOption) === '13') {
        dateRangeType = 'CAMPAIGN_TO_DATE';
      } else if (String(dates.dateRangeOption) === '0') {
        dateRangeType = 'CUSTOM';
      }

      const data = this.filters.campaignOptions;
      const result = data.filter((f) => f?.checked);
      const campaignIds = result?.map((res) => res.key) || [];
      const payload = {
        accountId: this?.account?.id,
        advertiserId: this.advertiser?.id,
        filters: {
          adGroups: null,
          audiences: this.selectedAudiences,
          campaignIds,
          conversionWindow: this.filters.conversionWindowDisplay,
          conversionWindowUnit: this.filters.conversionWindowUnit,
          creatives: this.selectedCreatives,
          dateRangeType,
          dimensions: this.selectedDimensionsOptions,
          endDate: this.getEndDate(dates, dateRangeType, mergedMetrics),
          startDate: this.getStartDate(dates, dateRangeType, mergedMetrics),
          interval: this.filters.interval?.toUpperCase(),
          keywords: null,
          mediaTypes: this.selectedMediaTypes,
          methodology: this.filters.methodology?.toUpperCase(),
          reportMetrics: mergedMetrics,
          pixelIds: null,
          publisher: this.selectedPublishers,
          showUniques: this.filters.showUniques,
        },
        name: this.reportName.trim(),
      };

      if (
        this.saveTemplate &&
        !this.reportName &&
        !['all_touch', 'last_touch'].includes(this.filters.template)
      ) {
        this.showErrorMessage(ERROR_MESSAGE_NAME_REQUIRED);
        return;
      }

      if (payload.filters.reportMetrics.some((metric) => metric?.metrics === 'ep')) {
        if (this.saveTemplate && this.filters.template === 'new') {
          this.buttonLabel = 'RUNNING...';
          // Only new template can be save
          const url = `${config.ADREADY_URL}/api/ac/advertisers/${this.advertiser?.id}/reportTemplate`;
          const apiCall = axios.post(url, payload, {
            withCredentials: true,
          });
          apiCall
            .then((response) => {
              if (response?.data?.status === 200) {
                // success saving the template
                if (this.runNow) {
                  // run now
                  EventBus.$emit('switch-to-tab-index', 2, payload.name);
                  this.triggerSearch(payload);
                } else {
                  // go to my reports
                  EventBus.$emit('switch-to-tab-index', 1);
                }
              }
            })
            .catch((error) => {
              // do not run report if there is an issue with saving template
              // Extract the error message from the response
              const errorMessage = error.response?.data?.message || 'An unknown error occurred';
              this.buttonLabel = 'RUN REPORT';
              // Handle the specific error or notify the user
              this.showErrorMessage(errorMessage);
            });
        } else if (this.runNow && !this.saveTemplate && this.filters.template === 'new') {
          this.buttonLabel = 'RUNNING...';
          EventBus.$emit('switch-to-tab-index', 2, '');
          this.triggerSearch(payload);
        } else if (this.saveTemplate && this.runNow && this.filters.template !== 'new') {
          this.buttonLabel = 'RUNNING...';
          EventBus.$emit('switch-to-tab-index', 2, payload.name);
          this.triggerSearch(payload);
        } else if (this.saveTemplate && !this.runNow && this.filters.template !== 'new') {
          this.showErrorMessage('Please modify any filter before running.');
        } else {
          this.showErrorMessage(ERROR_MESSAGE_DELIVERY_SELECTION);
        }
      } else if (['all_touch', 'last_touch'].includes(this.filters.template)) {
        this.buttonLabel = 'RUNNING...';
        let reportName = '';
        if (!this.selectedEvent[0]?.allSubCategoriesSelected) {
          reportName = this.selectedEvent[0]?.subEventLabels.join(', ');
        }

        if (reportName.length === 0) {
          reportName = this.formatEventNamesReverse(transformedEventOptions[0]?.metrics);
          reportName = reportName === 'Checkouts' ? 'Conversions' : reportName;
        }

        const reportType =
          this.filters.template === 'all_touch' ? 'All Touch Report' : 'Last Touch Report';
        reportName += ` ${reportType}`;

        EventBus.$emit('switch-to-tab-index', 2, reportName);
        payload.filters.template = this.filters.template;
        this.triggerSearch(payload);
      } else if (this.saveTemplate && this.filters.template === 'new') {
        this.buttonLabel = 'RUNNING...';
        // Only new template can be save
        const url = `${config.ADREADY_URL}/api/ac/advertisers/${this.advertiser?.id}/reportTemplate`;
        const apiCall = axios.post(url, payload, {
          withCredentials: true,
        });
        apiCall
          .then((response) => {
            if (response?.data?.status === 200) {
              // success saving the template
              if (this.runNow) {
                // run now
                EventBus.$emit('switch-to-tab-index', 2, payload.name);
                this.triggerReportSearchWithoutID(payload);
              } else {
                // go to my reports
                EventBus.$emit('switch-to-tab-index', 1);
              }
            }
          })
          .catch((error) => {
            // do not run report if there is an issue with saving template
            // Extract the error message from the response
            const errorMessage = error.response?.data?.message || 'An unknown error occurred';
            console.error('Error:', errorMessage);
            this.buttonLabel = 'RUN REPORT';
            // Handle the specific error or notify the user
            this.showErrorMessage(errorMessage);
          });
      } else if (this.runNow) {
        this.buttonLabel = 'RUNNING...';
        // run now
        let payLoad = {};
        if (this.selectedTemplateId && this.selectedTemplateId !== '') {
          payLoad = {
            id: this.selectedTemplateId,
            advertiserId: payload.advertiserId,
            name: payload.name,
            filters: payload.filters,
          };
        } else {
          payload.name = '';
          payLoad = payload;
        }
        EventBus.$emit('switch-to-tab-index', 2, payload.name);
        this.triggerReportSearch(payLoad);
      } else if (this.saveTemplate && !this.runNow && this.filters.template !== 'new') {
        this.showErrorMessage('Please select Run Now option.');
      } else {
        this.showErrorMessage(ERROR_MESSAGE_DELIVERY_SELECTION);
      }
    },
    getEndDate(dates, dateRangeType, mergedMetrics) {
      if (dateRangeType === 'CUSTOM') {
        return formatDateForAPI(dates.endDate);
      }
      if (
        ['all_touch', 'last_touch'].includes(this.filters.template) ||
        this.isEpSelected(mergedMetrics)
      ) {
        return formatDateForAPI(dates.endDate);
      }
      return null;
    },
    getStartDate(dates, dateRangeType, mergedMetrics) {
      if (dateRangeType === 'CUSTOM') {
        return formatDateForAPI(dates.startDate);
      }
      if (
        ['all_touch', 'last_touch'].includes(this.filters.template) ||
        this.isEpSelected(mergedMetrics)
      ) {
        return formatDateForAPI(dates.startDate);
      }
      return null;
    },
    isEpSelected(reportMetrics) {
      return reportMetrics.some((metric) => metric?.metrics === 'ep');
    },
    triggerReportSearchWithoutID(payload) {
      if (EventBus.myReportsWithIDListenerReady) {
        // If the listener is ready, emit the event
        EventBus.$emit('do-new-report-search-from-my-reports-with-id', payload);
      } else {
        // If the listener isn't ready, retry after a short delay
        setTimeout(() => this.triggerReportSearchWithoutID(payload), 200); // Retry after 200ms
      }
    },
    triggerReportSearch(payload) {
      if (EventBus.myReportsListenerReady) {
        // If the listener is ready, emit the event
        EventBus.$emit('do-new-report-search-from-my-reports', payload);
      } else {
        // If the listener isn't ready, retry after a short delay
        setTimeout(() => this.triggerReportSearch(payload), 200); // Retry after 200ms
      }
    },
    triggerSearch(payload) {
      this.$nextTick(() => {
        if (EventBus.listenerReady) {
          // If the listener is ready, emit the event
          EventBus.$emit('do-new-report-search', payload);
        } else {
          // If the listener isn't ready, retry after a short delay
          setTimeout(this.triggerSearch(payload), 100); // Retry after 100ms
        }
      });
    },
    setSelectedFilters(name, values, isMultiSelectFilter = false) {
      if (!this.isInitialCampaignChange) {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
      }
      this.isInitialCampaignChange = false;

      if (isMultiSelectFilter) {
        if (name === 'adGroupOptions') {
          this.selectedAdGroup = values;
        }
        this.setFilterOptions(name, values);
      } else {
        this.selectedFilters[name] = values.map((val) => {
          val.checked = true;
          return val;
        });
        const formattedOption = this.filters[name].map((opt) => {
          opt.checked = values.some((val) => val.value === opt.value);
          return opt;
        });
        this.setFilterOptions(name, formattedOption);
      }
    },
    setFilterOptions(name, values) {
      this.filters[name] = values;
      if (name === 'campaignOptions') {
        this.$store.set('dashboard/filters@campaignOptions', values);
      }
    },
    handleDimensionChange(opt) {
      this.$nextTick(() => {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }

        if (this.singleSelectModeDimension) {
          this.selectedDimensionsOptions = [opt];
          this.selectedReportKind = opt;
          this.setMetricOpts();
          return;
        }
        // Check if "Overall" is included in selectedDimensionsOptions
        const overallIndex = this.selectedDimensionsOptions.indexOf('overall');

        if (overallIndex === -1) {
          this.selectedReportKind = this.selectedDimensionsOptions.includes('publisher')
            ? 'publisher'
            : '';
          this.setMetricOpts();
          return;
        }

        if (overallIndex === this.selectedDimensionsOptions.length - 1) {
          this.selectedDimensionsOptions = ['overall'];
          this.selectedReportKind = opt;
          this.setMetricOpts();
          this.selectAllMetrics = false;
        } else if (overallIndex === 0) {
          this.selectedDimensionsOptions.splice(overallIndex, 1);
          this.selectedReportKind = opt;
          this.setMetricOpts();
        }
      });
    },
    toggleSelectAllMetrics() {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      if (this.selectAllMetrics) {
        this.disabledAllEvents = false;
        this.setMetricOptsForEvents(this.reportMetricsOptions);
        this.selectedMetricsOptions = this.reportMetricsOptions
          .map((option) => option.key)
          .filter((key) => key !== 'ep');
        this.singleSelectModeDimension = false;
        this.reportDimensionsOptions = this.reportDimensionsOptions.filter(
          (dimension) => !['day', 'daypart'].includes(dimension.id)
        );
      } else {
        const epIndex = this.reportMetricsOptions.findIndex((option) => option.key === 'ep');
        if (epIndex !== -1 && epIndex + 1 < this.reportMetricsOptions.length) {
          this.selectedMetricsOptions = [this.reportMetricsOptions[epIndex].key];
          this.handleMetricsChange('ep');
        } else {
          this.selectedMetricsOptions = [];
        }
      }
    },
    initializeMetricsSelection() {
      this.$nextTick(() => {
        this.selectAllMetrics = true;
        this.selectedMetricsOptions = this.reportMetricsOptions
          .map((option) => option.key)
          .filter((key) => key !== 'ep');
      });

      this.resetEventSelection();
    },
    handleMetricsChange(selected) {
      this.$nextTick(() => {
        if (this.disabledAllEvents) {
          this.resetEventSelection();
          this.disabledAllEvents = false;
        }

        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }

        if (selected === 'ep') {
          if (this.selectedMetricsOptions.length === 0) {
            this.selectAllMetrics = true;
            this.toggleSelectAllMetrics();
            this.resetEventSelection();
            this.disabledAllEvents = false;
            this.singleSelectModeDimension = false;
            this.reportDimensionsOptions = this.reportDimensionsOptions.filter(
              (dimension) => !['day', 'daypart'].includes(dimension.id)
            );
          } else {
            this.selectedMetricsOptions = ['ep'];
            this.selectAllMetrics = false;
            this.eventOptions.forEach((event) => {
              event.selected = false;
              if (event.children && event.children.length) {
                event.children.forEach((subCategory) => {
                  subCategory.selected = false;
                });
              }
            });
            this.disabledAllEvents = true;
            this.singleSelectModeDimension = true;
            this.filters.interval = 'cumulative';
            const dimension = this.reportDimensionsOptions;
            if (!dimension.some((item) => item.id === 'day')) {
              dimension.push({ id: 'day', name: 'Day', type: 'performance' });
            }
            if (!dimension.some((item) => item.id === 'daypart')) {
              dimension.push({ id: 'daypart', name: 'Daypart (EST)', type: 'performance' });
            }
            this.reportDimensionsOptions = dimension;
            this.selectedDimensionsOptions =
              this.selectedDimensionsOptions.length > 1
                ? [this.selectedDimensionsOptions[this.selectedDimensionsOptions.length - 1]]
                : this.selectedDimensionsOptions;
          }
          this.setMetricOptsForEvents(this.reportMetricsOptions);
          return;
        }

        if (this.selectedMetricsOptions.includes('ep')) {
          this.selectedMetricsOptions = this.selectedMetricsOptions.filter(
            (option) => option !== 'ep'
          );
          this.singleSelectModeDimension = false;
          this.reportDimensionsOptions = this.reportDimensionsOptions.filter(
            (dimension) => !['day', 'daypart'].includes(dimension.id)
          );
        }

        const allExceptEPSelected = this.reportMetricsOptions
          .filter((option) => option.key !== 'ep')
          .every((option) => this.selectedMetricsOptions.includes(option.key));

        this.selectAllMetrics = allExceptEPSelected;

        if (
          !this.selectedMetricsOptions.includes('ep') &&
          this.selectedMetricsOptions.length === 0
        ) {
          this.selectAllMetrics = true;
          this.toggleSelectAllMetrics();
        }

        // Ensure at least one item is selected
        if (this.selectedMetricsOptions.length === 0) {
          const epIndex = this.reportMetricsOptions.findIndex((option) => option.key === 'ep');
          if (epIndex !== -1 && epIndex + 1 < this.reportMetricsOptions.length) {
            this.selectedMetricsOptions = [this.reportMetricsOptions[epIndex + 1].key];
          }
        }
        this.setMetricOptsForEvents(this.reportMetricsOptions);
      });
    },
    toggleMainCategory(index) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }

      if (this.singleSelectMode) {
        this.eventOptions.forEach((event, i) => {
          if (i !== index) {
            event.selected = false;
          }
        });
      }
      const mainEvent = this.eventOptions[index];

      if (mainEvent.selected) {
        mainEvent.children?.forEach((subCategory) => {
          subCategory.selected = true;
        });
      }

      this.setMetricOptsForEvents(this.reportMetricsOptions);
      const allExceptEPSelected = this.reportMetricsOptions
        .filter((option) => option.key !== 'ep')
        .every((option) => this.selectedMetricsOptions.includes(option.key));

      this.selectAllMetrics = allExceptEPSelected;
    },
    resetSubCategories() {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }
    },
    onlyNumber($event) {
      const { key } = $event;

      // Allow numbers (0-9), Backspace, Delete, and control keys, but block the decimal (.)
      if (
        !/^[0-9]$/.test(key) &&
        key !== 'Backspace' &&
        key !== 'Delete' &&
        key !== 'Tab' &&
        key !== 'ArrowLeft' &&
        key !== 'ArrowRight'
      ) {
        $event.preventDefault();
      }
    },
    updateConversionWindowUnit(val) {
      if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
        this.filters.template = 'new';
        this.$store.set('dashboard/filters@template', 'new');
        this.reportName = '';
        this.selectedTemplateId = '';
      }
      this.isInitialConversionUnitChange = false;

      if (this.filters.conversionWindowUnit === val.name.toUpperCase()) {
        return;
      }

      if (val.name.toUpperCase() === 'DAYS') {
        this.filters.conversionWindowDisplay = Math.max(
          1,
          Math.round(this.filters.conversionWindowDisplay / 24) // Round the value after division
        );
      } else {
        this.filters.conversionWindowDisplay = Math.round(
          this.filters.conversionWindowDisplay * 24
        ); // Round the value after multiplication
      }
      this.filters.conversionWindowUnit = val.name.toUpperCase();
      this.validateConversionWindow();
    },
    validateConversionWindow() {
      if (!this.isInitialConversionWindowChange) {
        if (!['all_touch', 'last_touch', 'new'].includes(this.filters.template)) {
          this.filters.template = 'new';
          this.$store.set('dashboard/filters@template', 'new');
          this.reportName = '';
          this.selectedTemplateId = '';
        }
      }
      this.isInitialConversionWindowChange = false;

      const maxValue = this.filters.conversionWindowUnit === 'DAYS' ? 31 : 744;
      const value = parseFloat(this.filters.conversionWindowDisplay);

      if (value > maxValue) {
        this.filters.conversionWindowDisplay = maxValue;
      }
    },
    handleBlur() {
      const value = parseFloat(this.filters.conversionWindowDisplay);
      if (Number.isNaN(value) || value <= 0) {
        this.filters.conversionWindowDisplay = 1;
      }
    },
    handlePaste(event) {
      event.preventDefault();
      // Prevent the default paste behavior

      // Get the pasted text
      const pasteText = event.clipboardData.getData('text');

      // Sanitize the pasted text (remove anything that isn't a number)
      const sanitizedText = pasteText.replace(/[^0-9]/g, '');

      // Convert the current value to a string (in case it's a number)
      const currentValue = String(this.filters.conversionWindowDisplay);

      // Insert the sanitized text back into the input field
      const start = event.target.selectionStart;
      const end = event.target.selectionEnd;
      const newValue = currentValue.slice(0, start) + sanitizedText + currentValue.slice(end);

      // Update the value
      this.filters.conversionWindowDisplay = newValue;

      // Call the validation method after updating the value
      this.validateConversionWindow();
    },
    async getUserSavedReports(advertiserId) {
      try {
        const data = await appConsoleDataLayer.getUserSavedReports(advertiserId);
        if (data?.length > 0) {
          this.updateTemplateOptions(data);
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
        this.showErrorMessage(ERROR_MESSAGE_FETCH_TEMPLATE);
      }
    },
    updateTemplateOptions(data) {
      const dynamicOptions = this.mapAndSortDynamicOptions(data);
      const defaultTemplate = this.$store.copy('dashboard/filters@templateOptions');
      this.filters.templateOptions = [...defaultTemplate, ...dynamicOptions];
    },
    mapAndSortDynamicOptions(data) {
      return data
        .map((item) => ({
          id: item.id,
          name: item.name,
          filters: item.filters,
        }))
        .sort((a, b) => b.id - a.id);
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 21% 21% 15% 23% 20%;
  padding-top: 20px;
  font-family: 'Source Sans Pro', sans-serif;
}

.grid-item {
  padding: 10px;
  max-width: 230px;
  /* border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9; */
}

.section {
  display: block;
  margin-bottom: 8px;
}

.metrics-label {
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.filter {
  ::v-deep .dropdown .dropdown-menu {
    li svg {
      font-size: inherit !important;
    }

    .intip4 {
      top: 2px !important;
      margin-left: 5px !important;

      &::after {
        bottom: 200% !important;
        width: 198px !important;
        margin-left: -73px !important;
        font-weight: 400 !important;
        text-align: left !important;
        letter-spacing: 0em !important;
      }
    }
  }

  .filter-ttl {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.1em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  .filter-group-report > *:not(:first-child) {
    margin-top: 10px;
  }

  .filter-group-report {
    margin-bottom: 15px;

    .conv-content {
      position: relative;
      margin-top: 15px;

      .conv-ttl {
        position: absolute;
        top: -80%;
        font-size: 14px;
        color: #707e8a;
      }
    }

    .opt-b1 {
      display: inline-block;
      margin-right: 6px;
    }

    .opt-b2 {
      display: inline-block;
      width: 10rem;
      margin-left: 70px;
      vertical-align: bottom;
      position: absolute;
      margin-top: 5px;

      & .checkbox-btn {
        height: unset;
      }

      ::v-deep .checkbox-btn {
        span {
          position: fixed;
          margin: 0 30px !important;
          font-size: 12px !important;
        }
      }
    }

    .input-conv {
      width: 60px;
    }

    .flp-input {
      padding: 10px;
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.pan-content-top {
  /* margin-left: -26px !important; */
}

.aggregation {
  padding-top: 5px;
}

::v-deep .media-settings {
  .filter-group-report > *:not(:first-child) {
    margin-top: 12px !important;
  }

  .dropdown {
    width: 200px !important;
    min-width: 180px !important;
    margin: 0 0 0 0 !important;
  }
}

::v-deep .filter.media-settings .dropdown .dropdown-menu {
  max-height: 300px !important;
  overflow: hidden !important;
  overflow-y: auto !important;

  li svg {
    font-size: 17px !important;
    box-shadow: inset 0px 0px 1px 1px #454546;
    color: #fff;
  }
}

.advance-filter-clear-icon {
  position: relative;
  top: -2px;
  left: 5px;
  cursor: pointer;
  font-size: 13px;

  &:hover {
    color: var(--primarycolor) !important;
  }
}

.fp-text-link {
  font-size: 12px;
  color: #707e8a;
  display: inline-block;
  margin-top: 15px;
  display: flex;
  align-items: center;

  & p {
    text-decoration: underline;
    cursor: pointer;
  }

  & p:hover {
    color: var(--primarycolor) !important;
  }

  & span {
    text-decoration: none;
    margin-left: 8px;
    color: #212325 !important;

    .advance-filter-clear-icon:hover {
      color: var(--primarycolor) !important;
    }
  }

  & span:hover {
    color: #cad1d6;
  }
}

::v-deep .method-settings {
  .dropdown {
    width: 198px !important;
    min-width: 198px !important;
  }

  .filter-group-report {
    .tot-uniq,
    .conv-content {
      .conversion-opt-cont {
        display: flex;
      }
    }
  }
}

.dimension-item {
  margin: 5px 0;
}

.dimension-item input[type='checkbox'] {
  margin-right: 10px;
  cursor: pointer;
}

.dimension-item label {
  font-size: 14px;
  color: #333;
  cursor: pointer;
}

h3 {
  margin-bottom: 15px;
  color: #333;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.form-group input[type='checkbox'] {
  margin-right: 10px;
}

.schedule-options label,
.form-group label {
  margin-bottom: 5px;
}

input[type='text'],
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.run-report-button {
  background-color: var(--primarycolor);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
}

.run-report-button:hover {
  background-color: #003366;
}

.drp-wrap {
  font-size: 13px !important;
  padding: 6px 0px 6px 0px !important;
}

.media-select {
  font-size: 13px !important;
}

.filter {
  &.checkbox-select {
    position: relative;
    color: var(--primarydark2);
    max-width: 549px;
    width: 100%;

    max-height: 600px;
    /* Adjust the height */
    overflow-y: auto;
    /* Enable vertical scrolling */
  }

  .checkbox-select::-webkit-scrollbar {
    width: 8px;
  }

  .checkbox-select::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .checkbox-select::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .checkbox-select__trigger {
    border-radius: 10px;
    background: #fff;
    position: relative;
    z-index: 1;
    height: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 10px 8px 15px;
    border: 1px solid #c5d2d8;
    border-radius: 6px;
    transition: all 0.4s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkbox-select__check-wrapp {
    margin-bottom: 0px;
    position: relative;

    input[type='checkbox'] {
      display: none;

      & + label {
        position: relative;
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 22px;
        padding-left: 30px;
        display: inline-block;
        border-radius: 5px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: padding 0.25s ease;

        &:after {
          border: solid 2px var(--primarycolor);
          border-radius: 5px;
          content: '';
          width: 18px;
          height: 18px;
          opacity: 0.7;
          top: 2px;
          left: 0;
          position: absolute;
        }

        &:before {
          width: 14px;
          height: 14px;
          content: '';
          position: absolute;
          top: 0px;
          left: 4px;
          background-color: #fff;
          opacity: 0;
          will-change: transform;
          transform: scale(0.5);
          transition: all 0.2s ease;
        }

        &:hover {
          padding-left: 32px;
        }
      }

      &:disabled {
        color: var(--primarydark);
        opacity: 0.6;
        cursor: auto;
      }

      &:checked {
        & + label {
          &:before {
            margin: 1px 0px 0px 0px;
            opacity: 0.7;
            color: var(--primarycolor);
            font-size: 12px;
            font-family: FontAwesome;
            content: '\f00c';
            transform: scale(1);
          }
        }
      }
    }
  }
}

.checkbox-select__check-wrapp {
  margin-bottom: 10px;
}

.subcategory-container {
  margin-left: 20px;
  /* Indent subcategories */
  padding-top: 5px;
  /* Add some spacing from the parent */
}

.subcategory-item {
  margin-bottom: 8px;
  /* Spacing between subcategories */
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip-icon {
  margin-left: 5px;
  color: #5a5a5a;
  /* Neutral gray for icon */
  cursor: pointer;
}

.campaign-opts {
  width: 200px !important;
}

::v-deep .campaign-opts .checkbox-select {
  &__dropdown {
    max-width: 200px !important;
  }
}

.b-select {
  width: auto;
  min-width: 200px;
  max-width: 200px;
  margin-right: 20px !important;

  ::v-deep.select {
    margin-top: 0;

    .selected-opt {
      display: block;
      width: 164px !important;
    }
  }
}

::v-deep .dropdown {
  max-width: 200px;
  background-color: #30323a;
  transition: unset !important;

  .select {
    height: 40px;
  }

  .dropdown-menu {
    background-color: #30323a;

    li {
      display: flex;
      padding: 10px 12px !important;
      font-size: 13px !important;

      &:first-child {
        text-transform: capitalize !important;
      }
    }
  }
}

.dropdown-menu .grid-container {
  height: 400px;
  margin-bottom: 35px;
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -ms-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.metrics-opts {
  max-width: 440px !important;
}

.message-container {
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.custom-dimension-tooltip {
  position: absolute;
  top: 40px; /* Position above the input */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 12px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  text-align: center;
}

.custom-dimension-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}

.custom-interval-tooltip {
  position: absolute;
  top: 370px; /* Position above the input */
  left: 30%;
  transform: translateX(-50%);
  background-color: rgba(245, 245, 250, 1); /* White background */
  color: #333; /* Dark text color */
  padding: 8px 12px;
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-size: 12px;
  white-space: nowrap;
  z-index: 999;
  text-align: center;
}

.custom-interval-tooltip.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease-in-out; /* Smooth fade-in */
}
</style>
